
import React, {useEffect, useState} from 'react'

import {Link} from 'gatsby'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../../client-config'
import Img from 'gatsby-image'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import {Video} from '@splidejs/splide-extension-video'

import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css'
import '../../styles/splide.css'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'

const SliderImage = ({mainImage, alt}) => {
  const fluidProps = mainImage && mainImage.asset ? getFluidGatsbyImage(mainImage.asset._ref, {maxWidth: 1920, maxHeight: 1080}, clientConfig.sanity) : null
  return <Img loading='eager' fluid={fluidProps} alt={alt} style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }} />
}

const Cta = ({cta}) => {
  if (cta && cta.link && cta.linkText && cta.blank) {
    return (
      <a href={cta.link} className='splide__content-cta' target='_blank'>{cta.linkText}</a>
    )
  } else if (cta && cta.link && cta.linkText) {
    return (
      <Link to={`${cta.link}`} className='splide__content-cta'>{cta.linkText}</Link>
    )
  } else {
    return null
  }
}

export default ({slides, carousel}) => {
  // export default function Carousel ({slides}) {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {

    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const CustomDiv = ({slide, children, className}) => {
    if (slide?.url && (slide?.url?.includes('youtube') || slide?.url?.includes('youtu.be'))) {

      return (
        <SplideSlide className={`slide__wrapper ${className}`} data-splide-youtube={slide.url}>
          {children}
        </SplideSlide>
      )
    } else if(slide.url && slide?.url?.includes('vimeo')) {
      return (
        <SplideSlide className={`slide__wrapper ${className}`} data-splide-vimeo={slide.url}>
          {children}
        </SplideSlide>
      )
    } else {
      return (
        <SplideSlide className={`slide__wrapper ${className}`}>
          {children}
        </SplideSlide>
      )
    }
  }

  // if (typeof window === 'undefined') {
  if (!process.browser || !isMounted) {
    return null // Do not render on the server
  }

  const autoplay = carousel && carousel.autoplay ? carousel.autoplay : false
  const interval = carousel && carousel.interval ? carousel.interval : null
  console.log(autoplay, interval)
  return (

    <div className="relative">
    <Splide
      options={{
        type: 'fade',
        rewind: true,
        drag: true,
        speed: 800,
        easing: 'cubic-bezier(.42,.65,.27,.99)',
        gap: 0,
        keyboard: true,
        autoplay: autoplay ? true : false,
        interval: (autoplay && interval) ? interval : null,
        type: 'loop',
        pagination: false,
        video: {
          host: 'https://www.youtube-nocookie.com',
          loop: true,
          mute: true,
          height: '1920px',
          width: '1080px',
          playerOptions: {
            vimeo: {
              api: true,
              title: false,
              portrait: false,
              byline: false,
              responsive: true,
              autoplay: true,
              muted: true,
              loop: true,
              controls: false,
              playerOptions: {
                autoplay: true,
                muted: true,
                loop: true,
                controls: false
              }
            }
          }
        }
      }}
      Extensions={{Video}}
    >
      {slides.map((slide) => (
        <CustomDiv slide={slide} key={slide._key} className={(slide.slideTitle && slide.url) ? 'has-video-with-title' : ''}>
          <div className='slide__content'>
            {slide.slideTitle && <h2>{slide.slideTitle}</h2>}
            {slide.cta && <Cta cta={slide.cta} />}
          </div>
          {(slide.mainImage && slide.mainImage.asset) &&
            <SliderImage mainImage={slide.mainImage} alt={slide.mainImage.alt || slide.slideTitle} />}
        </CustomDiv>
      ))}

    </Splide>
    </div>
  )
}


