import React from 'react'
import Helmet from 'react-helmet'
import ClientOnly from '../../../../utils/ClientOnly'

function cfDonate () {
  return (
    <ClientOnly>
      <Helmet>
        <script src='https://my.communityfunded.com/collaborate.js?initId=37&embedKey=3b5566c3-79b7-48f8-8b17-e3e39f78d1b1&type=initiative' />
      </Helmet>
      <div className='community-funded' />

    </ClientOnly>
  )
}

export default cfDonate
