import React from 'react'
import Helmet from 'react-helmet'
import ClientOnly from '../../../../utils/ClientOnly'

function cfSchoolFees () {
  return (
    <ClientOnly>
      <Helmet>
        <script src='https://my.communityfunded.com/collaborate.js?initId=848&embedKey=ceda92bd-e910-4dd9-82ea-4c09d626c672&type=initiative' />
      </Helmet>
      <div className='community-funded' style={{width: '100%'}} />
    </ClientOnly>
  )
}

export default cfSchoolFees
